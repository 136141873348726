<template>
  <div class="CollaborateurRegistration">

    <CModal
      :show.sync="isAccountCreated"
      :no-close-on-backdrop="true"
      title="Compte créé"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Félicitations {{createCollaborateurFirstName}}, votre compte a bien été créé 👍
      </p>
      <p>
        Vous pouvez maintenant <router-link to="/pages/login">vous connecter</router-link> directement via votre email et votre mot de passe.
      </p>
      <template #footer>
        <CButton @click="$router.push('/pages/login')" color="primary">Me connecter</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="isExistingNewUserEmailModal"
      :no-close-on-backdrop="true"
      title="Compte déjà existant avec cette adresse"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Un compte utilisant cette adresse mail existe déjà ⛔
      </p>
      S'il s'agit de votre compte, vous pouvez réinitialiser votre mot de passe en renseignant votre
      adresse mail.
      <template #footer>
        <CButton @click="$router.push('/pages/settings/reset-password')" color="dark">Réinitialiser mon mot de passe</CButton>
      </template>
    </CModal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CContainer>
      <CRow class="mt-4">
        <CCol class="text-center">
          <h1 class="font-dosis-title" style="font-size: 32px !important; font-weight: bold">Eclerk</h1>
          <p>
            <span style="font-weight: 600; font-size: 20px;">Inscrivez-vous gratuitement et accéder au service Eclerk</span><br>
            <small> Déjà un compte ? <router-link to="/pages/login">Connectez-vous</router-link></small>
          </p>
          <p>
          </p>
        </CCol>
      </CRow>

      <CRow class="mt-3">
        <CCol :lg="{size: '10', offset:'1'}">
          <div class="card-style">
            <CProgress color="primary" :value="progress" size="sm"/>
          </div>


          <CCard v-if="currentStep==1">
            <CCardHeader>
              <div class="text-center"><strong class="font-dosis-title" style="font-size: 24px !important;">Identifiants</strong></div>
            </CCardHeader>
            <CCardBody>
              <CForm v-on:submit.prevent="onClickNext()">
                <CInput
                  addLabelClasses="font-weight-bold"
                  type="email"
                  maxlength="150"
                  placeholder="Email*"
                  @change="checkIfDuplicateUsername()"
                  size="lg"
                  v-model="createCollaborateurEmail"
                  @input="$v.createCollaborateurEmail.$touch(); hasCheckedUsernameDuplicate=false;"
                  :isValid="$v.createCollaborateurEmail.$dirty ? !$v.createCollaborateurEmail.$error : null"
                >
                  <template #description>
                    <small class="text-info">
                      Il s'agira de votre identifiant de connexion
                    </small><br>
                    <strong v-if="hasCheckedUsernameDuplicate && isExistingNewUserEmail" class="text-danger">
                      <CIcon name="cil-warning" /> {{ createCollaborateurEmail }} existe déjà. Veuillez choisir un autre nom d'utilisateur
                    </strong>
                    <strong v-if="hasCheckedUsernameDuplicate && !isExistingNewUserEmail" class="text-success">
                      <CIcon name="cil-check" /> {{ createCollaborateurEmail }} est disponible
                    </strong>

                  </template>
                </CInput>

                <CInput
                  type="password"
                  maxlength="249"
                  placeholder="Mot de passe"
                  size="lg"
                  v-model="createCollaborateurPassword"
                  @input="$v.createCollaborateurPassword.$touch()"
                  :isValid="$v.createCollaborateurPassword.$dirty ? !$v.createCollaborateurPassword.$error : null"
                  invalid-feedback="Votre mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial."
                >
                </CInput>

                <CInput
                  type="password"
                  maxlength="249"
                  placeholder="Confirmation du mot de passe"
                  size="lg"
                  v-model="createCollaborateurConfirmPassword"
                  @input="$v.createCollaborateurConfirmPassword.$touch()"
                  :isValid="$v.createCollaborateurConfirmPassword.$dirty ? !$v.createCollaborateurConfirmPassword.$error : null"
                  invalid-feedback="Veuillez rentrer deux mots de passe identiques"
                >
                </CInput>

                <div class="text-right">
                  <CButton
                    type="submit"
                    shape="pill"
                    size="lg"
                    color="primary"
                    :disabled="$v.createCollaborateurEmail.$invalid || $v.createCollaborateurPassword.$invalid || $v.createCollaborateurConfirmPassword.$invalid">
                    Suivant
                  </CButton>
                </div>
              </CForm>
            </CCardBody>
          </CCard>
          <CCard v-if="currentStep==2">
            <CCardHeader>
              <CRow>
                <CCol sm="2" class="align-self-center">
                  <CButton
                    class="pull-left" size="sm"
                    color="outline-dark" @click="onClickPrevious">
                     <CIcon name="cil-arrow-left" />
                  </CButton>
                </CCol>
                <CCol sm="8" class="text-center align-self-center">
                  <strong class="font-dosis-title" style="font-size: 24px !important;">
                    Profil
                  </strong>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CForm v-on:submit.prevent="onClickNext()">
                <CSelect
                  :value.sync="createCollaborateurSex"
                  size="lg"
                  addLabelClasses="font-weight-bold"
                  @input="$v.createCollaborateurSex.$touch()"
                  :isValid="$v.createCollaborateurSex.$dirty ? !$v.createCollaborateurSex.$error : null"
                  :options='userSexOptions'
                />
                <CRow>
                  <CCol md="6">
                    <CInput
                      addLabelClasses="font-weight-bold"
                      v-model="createCollaborateurFirstName" type="text"
                      maxlength="30"
                      placeholder="Prénom *"
                      size="lg"
                      @input="$v.createCollaborateurFirstName.$touch()"
                      :isValid="$v.createCollaborateurFirstName.$dirty ? !$v.createCollaborateurFirstName.$error : null"
                      invalid-feedback="Le prénom doit comporter entre 2 et 30 caractères"
                    >
                    </CInput>
                  </CCol>
                  <CCol md="6">
                    <CInput
                      addLabelClasses="font-weight-bold"
                      v-model="createCollaborateurLastName" type="text"
                      maxlength="150"
                      placeholder="Nom *"
                      size="lg"
                      @input="$v.createCollaborateurLastName.$touch()"
                      :isValid="$v.createCollaborateurLastName.$dirty ? !$v.createCollaborateurLastName.$error : null"
                      invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
                    >
                    </CInput>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol>
                    <label><strong class="text-dark"> Date de naissance</strong></label>
                    <CRow>
                      <CCol sm="3">
                        <CSelect
                          :value.sync="createCollaborateurBirthdateDay"
                          :options='daysArrayForm'
                          size="lg"
                          custom
                        />
                      </CCol>
                      <CCol sm="6">
                        <CSelect
                          :value.sync="createCollaborateurBirthdateMonth"
                          :options='monthsArrayForm'
                          size="lg"
                          custom
                        />
                      </CCol>
                      <CCol sm="3">
                        <CSelect
                          :value.sync="createCollaborateurBirthdateYear"
                          :options='yearsArrayForm'
                          size="lg"
                          custom
                        />
                      </CCol>
                    </CRow>
                    <small class="text-danger" v-if="$v.birthdateFormat.$invalid ">Veuillez saisir une date valide</small>
                  </CCol>
                </CRow>
                <CRow>

                  <CCol>
                    <div role="group" class="form-group">
                      <label for="createCollaborateurVuePhoneNumber" class="font-weight-bold">
                        <strong class="text-dark">Numéro de téléphone</strong>
                      </label>
                      <vue-phone-number-input
                        id="createCollaborateurVuePhoneNumber"
                        name="createCollaborateurVuePhoneNumber"
                        v-model="createCollaborateurPhoneNumber"
                        :default-country-code='createCollaborateurPhoneNumberResult.countryCode'
                        :preferred-countries="['FR', 'BE', 'DE']"
                        valid-color='#28a745'
                        error-color='#dc3545'
                        :translations="{
                          countrySelectorLabel: 'Code pays',
                          countrySelectorError: 'Choisir un pays',
                          phoneNumberLabel: 'Numéro de téléphone',
                          example: 'Exemple :'
                        }"
                        @update='createCollaborateurPhoneNumberResult = $event'
                        :required='false'
                      />
                      <small class="text-danger" v-if="createCollaborateurPhoneNumberResult.isValid == false">Veuillez rentrer un numéro de téléphone valide</small>
                    </div>
                  </CCol>
                </CRow>
                <div class="text-right">

                  <CButton
                    class="ml-2"
                    shape="pill"
                    size="lg"
                    type="submit"
                    color="primary"
                    :disabled="$v.createCollaborateurFirstName.$invalid || $v.createCollaborateurLastName.$invalid || $v.birthdateFormat.$invalid || $v.createCollaborateurPhoneNumberResult.$invalid">
                    Suivant
                  </CButton>
                </div>
              </CForm>

            </CCardBody>
          </CCard>
          <CCard v-if="currentStep==3">
            <CCardHeader>
              <CRow>
                <CCol sm="2" class="align-self-center">
                  <CButton
                    class="pull-left" size="sm"
                    color="outline-dark" @click="onClickPrevious">
                     <CIcon name="cil-arrow-left" />
                  </CButton>
                </CCol>
                <CCol sm="8" class="text-center align-self-center">
                  <strong class="font-dosis-title" style="font-size: 24px !important;">
                    Votre entreprise
                  </strong>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CForm v-on:submit.prevent="register()" >
              <CInput
                  v-model="createCompanyName" type="text"
                  maxlength="254"
                  size="lg"
                  @input="$v.createCompanyName.$touch()"
                  :isValid="$v.createCompanyName.$dirty ? !$v.createCompanyName.$error : null"
                  placeholder="Nom de votre entreprise"
                >
              </CInput>
              <CRow class="mt-md-4">
                <CCol md="6">
                  <CInput
                    v-model="createCompanySiret" type="text"
                    v-mask="'NNN NNN NNN NNNNN'"
                    maxlength="17" size="lg"
                    @input="$v.createCompanySiret.$touch()"
                    :isValid="$v.createCompanySiret.$dirty ? !$v.createCompanySiret.$error : null"
                    invalid-feedback="Votre numéro de SIRET doit être composé de 14 chiffres"
                    placeholder="Numéro de SIRET"
                  >
                  </CInput>
                </CCol>
                <CCol md="6">
                  <CInput
                    type="number"
                    size="lg"
                    v-model="createCompanyCapital"
                    @input="$v.createCompanyCapital.$touch()"
                    :isValid="$v.createCompanyCapital.$dirty ? !$v.createCompanyCapital.$error : null"
                    placeholder="Capital social en €">

                    <template #description>
                      <small class="text-info"><strong>Facultatif</strong> selon votre forme sociale</small>
                    </template>
                  </CInput>
                </CCol>
              </CRow>

              <CRow class="mt-md-2">
                <CCol md="6">
                  <vue-bootstrap-typeahead
                    size="lg"
                    v-model="createCompanyFormeSociale"
                    :data="companyFormeSocialeArrayOptions"
                    @hit="createCompanyFormeSociale = $event"
                    @input="$v.createCompanyFormeSociale.$touch()"
                    :isValid="$v.createCompanyFormeSociale.$dirty ? !$v.createCompanyFormeSociale.$error : null"
                    placeholder="Forme sociale"
                  />
                </CCol>
                <CCol md="6" class="mt-3 mt-md-0">
                  <CInput
                    type="text"
                    maxlength="149"
                    size="lg"
                    v-model="createCompanyPrecisionsReglementaires"
                    @input="$v.createCompanyPrecisionsReglementaires.$touch()"
                    :isValid="$v.createCompanyPrecisionsReglementaires.$dirty ? !$v.createCompanyPrecisionsReglementaires.$error : null"
                    invalid-feedback="Vos informations doivent faire entre 2 et 150 caractères"
                    placeholder="Précisions réglementaires, si nécessaire">
                    <template #description>
                      <small class="text-info">Ces informations apparaîtront sur les factures éditées via Eclerk.</small>
                    </template>
                  </CInput>
                </CCol>
              </CRow>


              <form autocomplete="eclerk-no-autocomplete-input">
                <label class="font-weight-bold" for="eclerk-create-company-address">
                  <strong class="text-dark">Adresse</strong>
                </label>
                <div class="input-group input-group-lg">
                  <vue-google-autocomplete
                    autocomplete="eclerk-no-autocomplete-input"
                    ref="eclerkcreateCompanyAddress"
                    id="eclerk-create-company-address"
                    name="eclerk-create-company-address"
                    classname="form-control"
                    maxlength="299"
                    size="lg"
                    placeholder="Renseignez l'adresse de votre entreprise"
                    @placechanged="chooseGoogleMapsCreateCompanyAddress"
                    @inputChange="createCompanyAddress = $event.newVal"
                    :enable-geolocation='true'
                  >
                  </vue-google-autocomplete>
                </div>
              </form>

              <CRow class="mt-3">
                <CCol md="6">
                  <CInput
                    type="email"
                    maxlength="150"
                    size="lg"
                    placeholder="Email de contact"
                    v-model="createCompanyEmail"
                    @input="$v.createCompanyEmail.$touch()"
                    :isValid="$v.createCompanyEmail.$dirty ? !$v.createCompanyEmail.$error : null"
                  >
                  </CInput>
                </CCol>
                <CCol md="6">
                  <div role="group" class="form-group">
                    <vue-phone-number-input
                      id="createCompanyVuePhoneNumber"
                      name="createCompanyVuePhoneNumber"
                      v-model="createCompanyPhoneNumber"
                      :default-country-code='createCompanyPhoneNumberResult.countryCode'
                      :preferred-countries="['FR', 'BE', 'DE']"
                      valid-color='#28a745'
                      error-color='#dc3545'
                      :translations="{
                        countrySelectorLabel: 'Code pays',
                        countrySelectorError: 'Choisir un pays',
                        phoneNumberLabel: 'Numéro de téléphone de contact',
                        example: 'Exemple :'
                      }"
                      @update='createCompanyPhoneNumberResult = $event'
                      :required='false'
                    />
                    <small class="text-danger" v-if="createCompanyPhoneNumberResult.isValid == false">Veuillez rentrer un numéro de téléphone valide</small>
                  </div>
                </CCol>
              </CRow>

              <CInputCheckbox
                  class="mt-3"
                  name="createCompanyHasAccepted"
                  :checked.sync="has_accepted_cgu_cgv_donnes"
                >
                <template #label >
                  <div style="cursor: pointer" @click="has_accepted_cgu_cgv_donnes = !has_accepted_cgu_cgv_donnes">
                  J'accepte les
                    <a @click.stop href="https://eclerk.io/informations/conditions-utilisation" target="_blank" class="text-dark">
                      conditions générales d'utilisation
                    </a> et les
                    <a @click.stop href="https://eclerk.io/informations/conditions-vente" target="_blank" class="text-dark">
                      conditions générales de vente
                    </a>
                    .
                    Vos données sont collectés et traitées conformément à notre
                    <a @click.stop href="https://eclerk.io/informations/politique-de-confidentialite" target="_blank" class="text-dark">
                      politique de données personnelles
                    </a>
                  </div>

                </template>

                </CInputCheckbox>

              <div class="text-right">
                <CButton
                  class="ml-2"
                  type="submit"
                  shape="pill"
                  size="lg"
                  color="primary"
                  :disabled="!isValidRegistrationData">
                  Confirmer mon inscription
                </CButton>
              </div>
            </CForm>
            </CCardBody>
          </CCard>


        </CCol>
      </CRow>
    </CContainer>



  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, sameAs, integer, numeric, minValue, maxValue } from 'vuelidate/lib/validators'
import { password_validation, isDate, isStringIntegerOrBlank } from '@/validators/validators'

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

const Loading = () => import('vue-loading-overlay')
import 'vue-loading-overlay/dist/vue-loading.css';


import { AuthAPIService } from '@/api/APIAuth'

const apiAuthConnected = new AuthAPIService()


export default {
  name: 'CollaborateurRegistration',
  mixins: [
    validationMixin,
  ],
  components: {
    VueBootstrapTypeahead,
    VueGoogleAutocomplete,
    Loading
  },
  data: function () {
    return {
      isRegisterInProcess: false,
      isCheckingUsername: false,
      isExistingNewUserEmail: false,
      isExistingNewUserEmailModal: false,
      hasCheckedUsernameDuplicate: false,

      isAccountCreated: false,

      currentStep: 1,
      max_step: 3,

      has_accepted_cgu_cgv_donnes: false,

      monthsArrayForm: [
        { label: 'Janvier', value: '01' },
        { label: 'Février', value: '02' },
        { label: 'Mars', value: '03' },
        { label: 'Avril', value: '04' },
        { label: 'Mai', value: '05' },
        { label: 'Juin', value: '06' },
        { label: 'Juillet', value: '07' },
        { label: 'Août', value: '08' },
        { label: 'Septembre', value: '09' },
        { label: 'Octobre', value: '10' },
        { label: 'Novembre', value: '11' },
        { label: 'Décembre', value: '12' },
      ],

      // ---- COLLABORATEUR ---------


      userSexOptions: [
        { label: 'Monsieur', value: 'H' },
        { label: "Madame", value: 'F' },
      ],

      companyFormeSocialeArrayOptions: [
        'Entreprise individuelle',
        "Entreprise unipersonnelle à responsabilité limitée",
        "Société à responsabilité limitée",
        "Société par actions simplifiée",
        "Société en nom collectif",
        "Société coopérative de production",
        "Société civile professionnelle",
      ],
    }
  },

  computed: {
    isValidRegistrationData: function() {
      if (this.$v.createCollaborateurEmail.$invalid || this.$v.createCollaborateurPassword.$invalid || this.$v.createCollaborateurConfirmPassword.$invalid ||
        this.$v.createCollaborateurFirstName.$invalid || this.$v.createCollaborateurLastName.$invalid || this.$v.createCollaborateurPhoneNumberResult.$invalid ||
        this.$v.createCompanyName.$invalid || this.$v.createCompanySiret.$invalid || this.$v.createCompanyFormeSociale.$invalid || this.$v.createCompanyPrecisionsReglementaires.$invalid ||
        this.$v.createCompanyCapital.$invalid || this.$v.createCompanyAddress.$invalid || this.$v.createCompanyPhoneNumberResult.$invalid || this.$v.createCompanyEmail.$invalid ||
        !this.has_accepted_cgu_cgv_donnes) {
          return false
        }
      return true

    },
    progress: function() {
      return Math.round(100 / this.max_step) * this.currentStep;
    },

    isLoading () {
      if (this.isRegisterInProcess || this.isCheckingUsername) {
        return true
      }
      return false
    },
    createCollaborateurEmail: {
      get () {
        return this.$store.state.createCollaborateurEmail
      },
      set (value) {
        this.$store.commit('set', ['createCollaborateurEmail', value])
      }
    },
    createCollaborateurPassword: {
      get () {
        return this.$store.state.createCollaborateurPassword
      },
      set (value) {
        this.$store.commit('set', ['createCollaborateurPassword', value])
      }
    },
    createCollaborateurConfirmPassword: {
      get () {
        return this.$store.state.createCollaborateurConfirmPassword
      },
      set (value) {
        this.$store.commit('set', ['createCollaborateurConfirmPassword', value])
      }
    },

    createCollaborateurFirstName: {
      get () {
        return this.$store.state.createCollaborateurFirstName
      },
      set (value) {
        this.$store.commit('set', ['createCollaborateurFirstName', value])
      }
    },
    createCollaborateurLastName: {
      get () {
        return this.$store.state.createCollaborateurLastName
      },
      set (value) {
        this.$store.commit('set', ['createCollaborateurLastName', value])
      }
    },
    createCollaborateurSex: {
      get () {
        return this.$store.state.createCollaborateurSex
      },
      set (value) {
        this.$store.commit('set', ['createCollaborateurSex', value])
      }
    },
    createCollaborateurBirthdate: {
      get () {
        return this.$store.state.createCollaborateurBirthdate
      },
      set (value) {
        this.$store.commit('set', ['createCollaborateurBirthdate', value])
      }
    },
    createCollaborateurBirthdateYear: {
      get () {
        return this.$store.state.createCollaborateurBirthdateYear
      },
      set (value) {
        this.$store.commit('set', ['createCollaborateurBirthdateYear', value])
      }
    },
    createCollaborateurBirthdateMonth: {
      get () {
        return this.$store.state.createCollaborateurBirthdateMonth
      },
      set (value) {
        this.$store.commit('set', ['createCollaborateurBirthdateMonth', value])
      }
    },
    createCollaborateurBirthdateDay: {
      get () {
        return this.$store.state.createCollaborateurBirthdateDay
      },
      set (value) {
        this.$store.commit('set', ['createCollaborateurBirthdateDay', value])
      }
    },
    createCollaborateurPhoneNumber: {
      get () {
        return this.$store.state.createCollaborateurPhoneNumber
      },
      set (value) {
        this.$store.commit('set', ['createCollaborateurPhoneNumber', value])
      }
    },
    createCollaborateurPhoneNumberResult: {
      get () {
        return this.$store.state.createCollaborateurPhoneNumberResult
      },
      set (value) {
        this.$store.commit('set', ['createCollaborateurPhoneNumberResult', value])
      }
    },

    createCompanyName: {
      get () {
        return this.$store.state.createCompanyName
      },
      set (value) {
        this.$store.commit('set', ['createCompanyName', value])
      }
    },
    createCompanySiret: {
      get () {
        return this.$store.state.createCompanySiret
      },
      set (value) {
        this.$store.commit('set', ['createCompanySiret', value])
      }
    },
    createCompanyFormeSociale: {
      get () {
        return this.$store.state.createCompanyFormeSociale
      },
      set (value) {
        this.$store.commit('set', ['createCompanyFormeSociale', value])
      }
    },
    createCompanyPrecisionsReglementaires: {
      get () {
        return this.$store.state.createCompanyPrecisionsReglementaires
      },
      set (value) {
        this.$store.commit('set', ['createCompanyPrecisionsReglementaires', value])
      }
    },
    createCompanyCapital: {
      get () {
        return this.$store.state.createCompanyCapital
      },
      set (value) {
        this.$store.commit('set', ['createCompanyCapital', value])
      }
    },
    createCompanyAddress: {
      get () {
        return this.$store.state.createCompanyAddress
      },
      set (value) {
        this.$store.commit('set', ['createCompanyAddress', value])
      }
    },
    createCompanyPhoneNumber: {
      get () {
        return this.$store.state.createCompanyPhoneNumber
      },
      set (value) {
        this.$store.commit('set', ['createCompanyPhoneNumber', value])
      }
    },

    createCompanyPhoneNumberResult: {
      get () {
        return this.$store.state.createCompanyPhoneNumberResult
      },
      set (value) {
        this.$store.commit('set', ['createCompanyPhoneNumberResult', value])
      }
    },

    createCompanyEmail: {
      get () {
        return this.$store.state.createCompanyEmail
      },
      set (value) {
        this.$store.commit('set', ['createCompanyEmail', value])
      }
    },

    finalCreateCompanySiret () {
      return this.createCompanySiret.replace(/\s/g,'');
    },

    // ---- Date
    daysArrayForm () {
      var days = []
      for (var i=1; i<=31; i++) {
        var value = String(i)
        if (i <= 9) {
          value = "0" + String(i)
        }
        days.push({'text': value, 'value': value})
      }
      return days
    },

    yearsArrayForm () {
      var years = []
      for (var i=1945; i<=2010; i++) {
        var value = String(i)
        years.push({'text': value, 'value': value})
      }
      return years
    },

    birthdateFormat () {
      return this.createCollaborateurBirthdateYear + '-' + this.createCollaborateurBirthdateMonth + '-' + this.createCollaborateurBirthdateDay
    }
  },

  validations: {
    createCollaborateurEmail: {
      required,
      email,
      maxLength: maxLength(249),
      minLength: minLength(2)
    },
    createCollaborateurPassword: {
      required,
      password_validation,
      maxLength: maxLength(249),
    },
    createCollaborateurConfirmPassword: {
      required,
      maxLength: maxLength(249),
      sameAsPassword: sameAs('createCollaborateurPassword'),
    },

    createCollaborateurFirstName: {
      required,
      maxLength: maxLength(30),
      minLength: minLength(2)
    },
    createCollaborateurLastName: {
      required,
      maxLength: maxLength(150),
      minLength: minLength(2)
    },
    createCollaborateurSex: {
      required
    },
    birthdateFormat: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    createCollaborateurPhoneNumberResult: {
      countryCode: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(2)
      },
      isValid: {
        isValid(value) {
          return value
        }
      },
      nationalNumber: {
        required,
        integer
      }
    },
    createCompanyName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(255)
    },
    createCompanySiret: {
      required,
      minLength: minLength(17),
      maxLength: maxLength(17),
      validSiret(value) {
        return isStringIntegerOrBlank(value)
      },
    },
    createCompanyFormeSociale: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(149)
    },
    createCompanyPrecisionsReglementaires: {
      minLength: minLength(2),
      maxLength: maxLength(149)
    },
    createCompanyCapital: {
      numeric,
      maxValue: maxValue(100000000),
      minValue: minValue(1)
    },
    createCompanyAddress: {
      required,
      maxLength: maxLength(249),
      minLength: minLength(2)
    },
    createCompanyPhoneNumberResult: {
      countryCode: {
        minLength: minLength(2),
        maxLength: maxLength(2)
      },
      isValid: {
        isValid(value) {
          return value
        }
      }
    },
    createCompanyEmail: {
      required,
      email,
      minLength: minLength(2),
      maxLength: maxLength(255)
    },
  },


  methods: {
    onClickNext () {
      this.currentStep ++
    },
    onClickPrevious () {
      this.currentStep --
    },

    chooseGoogleMapsCreateCompanyAddress: function (addressData) {
      this.createCompanyAddress = addressData['street_number'] + ', ' + addressData['route'] + ', ' + addressData['locality'] + ', ' + addressData['country'];
    },

    checkIfDuplicateUsername () {
      if (this.$v.createCollaborateurEmail.$invalid) {
        this.isExistingNewUserEmail = false
        this.hasCheckedUsernameDuplicate = false
        return null
      }
      else {
        this.isCheckingUsername = true
        apiAuthConnected.checkIfDuplicateUsername(this.createCollaborateurEmail)
          .then((result) => {
            if (result.data.is_ok) {
              this.isExistingNewUserEmail = false
              this.createCompanyEmail = this.createCollaborateurEmail
            }
            else {
              this.isExistingNewUserEmail = true
              this.isExistingNewUserEmailModal = true
            }
          })
          .finally(() => {
            this.isCheckingUsername = false
            this.hasCheckedUsernameDuplicate = true
          })
      }
    },

    // Executed when @stepper-finished event is triggered
    register() {
      this.isRegisterInProcess = true
      apiAuthConnected.register(this.createCompanyName, this.finalCreateCompanySiret, this.createCompanyFormeSociale,
        this.createCompanyPrecisionsReglementaires, this.createCompanyCapital, this.createCompanyAddress,
        this.createCompanyPhoneNumberResult.countryCode,
        this.createCompanyPhoneNumberResult.nationalNumber, this.createCompanyEmail, this.has_accepted_cgu_cgv_donnes,
        this.createCollaborateurEmail, this.createCollaborateurPassword, this.createCollaborateurConfirmPassword,
        this.createCollaborateurFirstName, this.createCollaborateurLastName, this.createCollaborateurSex,
        this.birthdateFormat, this.createCollaborateurPhoneNumberResult.countryCode,
        this.createCollaborateurPhoneNumberResult.nationalNumber)
      .then(() => {
          this.isAccountCreated = true
      })
      .catch((error) => {
        try {
          if (error.response && error.response.data.collaborateur_username && error.response.data.collaborateur_username[0] === "Un utilisateur avec cet email existe déjà") {
            this.isExistingNewUserEmailModal = true
          }
          else {
            this.$store.commit('openGlobalErrorModal')
          }
        }
        catch {
          this.$store.commit('openGlobalErrorModal')
        }

      })
      .finally(() => {
        this.isRegisterInProcess = false
      })
    }
  }
}
</script>
